import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ProductList from '../components/Products/ProductList';
import { PageHeader } from '../components/PageHeader';
import { decodeEntities } from '../utils/helpers';
import RenderContent from '../components/RenderContent';

export default class Category extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const { postsOnPage, categories, site, wordpressWpSettings } = data
    const { edges: posts, totalCount } = postsOnPage
    const { title: siteTitle } = wordpressWpSettings
    const { name: category, slug, description, pathPrefix } = pageContext
    const title = `${category}`

    return (
      <Layout className={`page-wrap`} location={location}>
        <SEO title={`${decodeEntities(category)} | ${decodeEntities(siteTitle)}`} />
        <PageHeader headerTitle={title} headerBackgroundImage="" location={location} />
        {description && <div className="category-intro">
          <div className="inner">
            <RenderContent content={description}/>
            <div className="side">
              <div className="block orange">
                <div className="entry-content">
                  <h3>ON A BUDGET?</h3>
                   <p>Browse our range of budget USB models and pricing here.</p>
                  <p><a className="action" href="/specials/">View Budget USBs</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>}
        <ProductList
          posts={posts}
          title={title}
          pageContext={pageContext}
          categories={categories.edges}
          siteMetadata={wordpressWpSettings}
          pathPrefix={pathPrefix}
        />
      </Layout>
    )
  }
}

Category.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query ProductCategoryPage($category_id: [Int], $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
    }
    postsOnPage: allWordpressWpProducts(
      filter: {
        product_categories: {in: $category_id}
      }
      sort: { order: ASC, fields: ord }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProductListFields
        }
      }
    }
    categories: allWordpressWpProductCategories(
      filter: { 
        count: { gt: 0 } 
      }
      sort: { order: ASC, fields: ord }
    ) {
      edges {
        node {
          name
          slug
          path
          count
          ord
          wordpress_parent
          wordpress_id
        }
      }
    }
  }
`
