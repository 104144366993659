import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby';
import './ProductList.scss';
import RenderContent from '../../RenderContent';
import Pagination from '../../Pagination';
import ProductListItem from '../ProductListItem'
import { ProductListNavigation } from '../ProductListNavigation'

export default class IndexPage extends React.Component {
  render() {
    const { posts, pageContext, categories, pathPrefix, siteMetadata} = this.props
    return (
      <section className="product-list">
        {/*<ProductListNavigation pathPrefix={pathPrefix} categories={categories} posts={posts} pageContext={pageContext} postSlug="/products/"/>*/}
        <div className="outer">
          <div className="wrap">
            <div className="inner">
              <div className="list">
                {posts.map((slide, inedx) => {
                  return (
                    <ProductListItem key={inedx} data={slide.node} />
                  )
                })}
              </div>
              <Pagination pageContext={pageContext} pathPrefix={pathPrefix} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment ProductListFields on wordpress__wp_products {
    id
    wordpress_id
    product_categories
    title
    excerpt
    date(formatString: "MMMM Qo gggg")
    slug
    path
    featured_media {
      localFile {
        childImageSharp {
          fluid(maxWidth: 670, quality: 70) {
            ... GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`
